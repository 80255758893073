import { Helmet } from 'react-helmet-async';
import { useGetCustomerSegmentation } from 'src/apis/userSettingsAPI';
// This need to be uncommented when we are allowed to use segmentations
// import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import useUserInfo from 'src/apis/usersAPI/meAPI';
import { isDev } from 'src/utils/env';

const DigitalAdoptionPlugin = () => {
  const { userInfo, isSuccess: idIsSuccess } = useUserInfo();
  // This need to be uncommented when we are allowed to use segmentations
  // const { shortNameWithoutUrl, isSuccess: shortNameIsSuccess } = useGetShortNameWithoutUrl();
  const { customerSegmentation, isSuccess: customerSegmentationSuccess } =
    useGetCustomerSegmentation();

  // This need to be uncommented when we are allowed to use segmentations
  // const loadScript = idIsSuccess && shortNameIsSuccess && customerSegmentationSuccess;
  const loadScript = idIsSuccess && customerSegmentationSuccess;

  const pendoScript = {
    visitor: {
      id: `${customerSegmentation?.customerProductId}_${userInfo.userId}`,
    },
    account: {
      id: customerSegmentation?.customerProductId,
    },
    // This need to be uncommented when we are allowed to use segmentations
    // visitor: {
    //   id: `${shortNameWithoutUrl}_${userInfo.userId}`,
    //   ...(customerSegmentation && customerSegmentation),
    // },
    // account: {
    //   id: shortNameWithoutUrl,
    // },
  };

  if (isDev) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">{`
        (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })('${process.env.REACT_APP_PENDO_API_KEY}');
      `}</script>

      {loadScript && (
        <script type="text/javascript">{`
          pendo.initialize(${JSON.stringify(pendoScript)});
        `}</script>
      )}
    </Helmet>
  );
};

export default DigitalAdoptionPlugin;
